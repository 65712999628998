import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link, graphql, useStaticQuery } from 'gatsby'
import Navigation from '../Navigation/NavContainer'
import PaperPlaneIcon from "../Icon/PaperPlaneIcon";

const Header = ({siteMetadata, showFestivalBanner= true}) => {
	const data = useStaticQuery(graphql`{
		siteLogoFestival: file(relativePath: {eq: "2024/Bozzy24-Web-Logo.png"}) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		siteLogo: file(relativePath: {eq: "Bozzy-2025-Logo-001.png"}) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
	}`)

	const { boxOfficeEnabled, boxOfficePresale } = siteMetadata.config
	const siteLogoImageData = boxOfficeEnabled || boxOfficePresale
		? data.siteLogoFestival.childImageSharp.gatsbyImageData
		: data.siteLogo.childImageSharp.gatsbyImageData

	return (
		<div>
			<header className="min-h-24 p-4 md:px-12 bg-gradient-to-br from-green-900 to-bbf-dark-green border-b-4 border-bbf-dark-green">
				{/* logo and title */}
				<div className="md:flex md:items-center">
					<div className={`flex-col flex-shrink-0 w-64`}>
						<Link to="/" title="Boswell Book Festival Home" className="hover:opacity-75 transition-opacity">
							<GatsbyImage
								image={siteLogoImageData}
								alt="Boswell Book Festival Logo"
							/>
						</Link>
						<Link
							to="/join-our-mailing-list"
							title="Join the Boswell Book Festival mailing list"
							className="text-white hover:opacity-75 transition-opacity">
							<p className={`mt-4 text-sm flex items-center gap-1`}>Join our mailing list <PaperPlaneIcon /></p>
						</Link>
					</div>
					<div className="md:ml-auto">
						{/* advertising / permanent banners */}
						{showFestivalBanner && (
							<div className="mt-4 md:mt-0 md:mb-4">
								<h2 className="font-heading text-bbf-mid-green-alt text-xl md:text-right font-bold">The World's Only Festival of Biography and Memoir</h2>
								{/*<h3 className="font-heading text-bbf-yellow text-2xl md:text-3xl md:text-right font-bold">2021 Festival Rerun 29 - 31 October ONLY</h3>*/}
								{/*<h3 className="font-heading text-bbf-pink text-2xl md:text-3xl md:text-right font-bold">2022 Festival 12-15 May</h3>*/}
								{/*<h3 className="font-heading text-bbf-pink text-2xl md:text-4xl md:text-right font-bold uppercase">10-12 May 2024</h3>*/}
								<h3 className="font-heading text-bbf-pink text-2xl md:text-4xl md:text-right font-bold uppercase">9-11 May 2025</h3>
								{/*<Link*/}
								{/*	to="/join-our-mailing-list"*/}
								{/*	title="Join the Boswell Book Festival mailing list"*/}
								{/*	className="text-white md:text-right hover:opacity-75 transition-opacity">*/}
								{/*	<p className={`mt-2`}>Join our mailing list</p>*/}
								{/*</Link>*/}
							</div>
						)}
						{/* navigation */}
						<Navigation siteMetadata={siteMetadata} />
					</div>
				</div>
			</header>
		</div>
    );
}

export default Header
