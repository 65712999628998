import React, { forwardRef } from 'react'
import { Link } from 'gatsby'
import GiftIcon from './Icon/GiftIcon'

const DonateButton = forwardRef((props, ref) => {
	const { amount, title } = props

	return (
		<Link
			className="p-2 px-3 shadow text-sm uppercase border-2 border-bbf-yellow bg-bbf-yellow text-black transition-all hover:shadow-md flex items-center justify-center focus:outline-none focus:ring-4 focus:ring-bbf-yellow focus:ring-opacity-50"
			to="/donate"
			title={ title || `Support the Boswell Book Festival` }
		>
			<p className="mr-2">{ props.buttonText || 'Donate'}{ amount ? <span className={`font-bold`}> £{amount}</span> : ''}</p>
			<GiftIcon />
		</Link>
	)
})

export default DonateButton
