import React, { useState } from 'react'
import { Link } from 'gatsby'
import NavHamburger from './NavHamburger'
import DonateButton from '../DonateButton'
// import DonateFlyout from '../DonateFlyout'
import Tippy from '@tippyjs/react'
import SnipcartShoppingCart from "../Shop/SnipcartShoppingCart"

import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/perspective.css'

const Navigation = (props) => {

	const [navIsOpen, setNavIsOpen] = useState(false)

	const handleToggleNav = () => {
		setNavIsOpen(!navIsOpen)
	}

	const { shopEnabled } = props.siteMetadata

	const navData = [
		{ to: `/`, label: `Home`, title: `Boswell Book Festival Homepage` },
		{ to: `/about`, label: `About`, title: `About the Boswell Book Festival`, category: 'about', subNav: true },
		// { to: `/2021/events`, label: `2021`, title: `About the 2021 Boswell Book Festival Event`, category: '2021', subNav: true },
		// { to: `/2022/events`, label: `2022`, title: `About the 2022 Boswell Book Festival Event`, category: '2022', subNav: true },
		// { to: `/2023/events`, label: `2023`, title: `About the 2023 Boswell Book Festival Event`, category: '2023', subNav: true },
		// { to: `/2024/events`, label: `2024`, title: `About the 2024 Boswell Book Festival Event`, category: '2024', subNav: true },
		{ to: `/get-involved`, label: `Get Involved`, title: `Get involved with the Boswell Book Festival`, category: 'support', subNav: true },
		{ to: `/sponsor`, label: `Sponsors`, title: `Sponsor the Boswell Book Festival`, category: 'sponsors' },
		{ to: `/contact`, label: `Contact`, title: `Contact the Boswell Book Festival team`, category: 'contact', subNav: true }
	]

	if (shopEnabled) {
		navData.push({ to: `/shop`, label: `Shop`, title: `Boswell Book Festival Shop`, category: 'shop', subNav: false })
	}

	const subNavData = {
		about: [
			{ to: `/about`, label: `About the Festival`, title: `About the Boswell Book Festival` },
			{ to: `/childrens-festival`, label: `Children's Festival`, title: `About the Children's Festival` },
			{ to: `/location`, label: `Location`, title: `Boswell Book Festival location` },
			// { to: `/how-to-find-us`, label: `How to Find us`, title: `How to find the Boswell Book Festival` },
			{ to: `/2024/events`, label: `2024 Archive`, title: `Events from the 2024 Boswell Book Festival` },
			{ to: `/2023/events`, label: `2023 Archive`, title: `Events from the 2023 Boswell Book Festival` },
			{ to: `/2022/events`, label: `2022 Archive`, title: `Events from the 2022 Boswell Book Festival` },
			{ to: `/2021/events`, label: `2021 Archive`, title: `Events from the 2021 Boswell Book Festival` },
			{ to: `/2021/festival-plus`, label: `Festival Plus+`, title: `Boswell Book Festival 2021 Festival Plus+ content` },
			{ to: `/gallery/2023`, label: `Gallery`, title: `Images from the Boswell Book Festival` },
		],
		'2021': [
			// { to: `/2021/box-office`, label: `Festival Rerun Box Office`, title: `Boswell Book Festival Rerun 2021`, locationState: {festivalType: 'main'} },
			// { to: `/2021/box-office`, label: `Festival Box Office`, title: `Boswell Book Festival 2021 events and information`, locationState: {festivalType: 'main'} },
			// { to: `/2021/childrens-festival-box-office`, label: `Children's Festival Box Office`, title: `Boswell Book Festival 2021 Children's Festival events and information`, locationState: {festivalType: 'childrens'} },
			{ to: `/2021/events`, label: `Festival Events`, title: `Boswell Book Festival 2021 events and information` },
			{ to: `/2021/childrens-festival-events`, label: `Children's Festival Events`, title: `Boswell Book Festival 2021 Children's Festival events and information` },
			{ to: `/2021/festival-plus`, label: `Festival Plus+`, title: `Boswell Book Festival 2021 Festival Plus+ content` },
			{ to: `/2021/creators-gallery`, label: `Children's Festival Gallery`, title: `Creators gallery highlighting the work of schools and families during the festival.` }
		],
		'2022': [
			// { to: `/2022/box-office`, label: `Festival Box Office`, title: `Boswell Book Festival 2021 events and information`, locationState: {festivalType: 'main'} },
			// { to: `/2022/childrens-festival-box-office`, label: `Children's Festival Box Office`, title: `Boswell Book Festival 2021 Children's Festival events and information`, locationState: {festivalType: 'childrens'} },
			{ to: `/2022/events`, label: `Festival Events`, title: `Boswell Book Festival 2022 events and information` },
			{ to: `/2022/childrens-festival-events`, label: `Children's Festival Events`, title: `Boswell Book Festival 2022 Children's Festival events and information` },
			{ to: `/2022/schools-events`, label: `Schools Events`, title: `Boswell Book Festival 2022 Schools events and information` },
		],
		'2023': [
			{ to: `/2023/events`, label: `Festival Events`, title: `Boswell Book Festival 2023 events and information` },
			{ to: `/2023/childrens-festival-events`, label: `Children's Festival Events`, title: `Boswell Book Festival 2023 Children's Festival events and information` },
			{ to: `/2023/schools-events`, label: `Schools Events`, title: `Boswell Book Festival 2023 Schools events and information` },
		],
		'2024': [
			{ to: `/2024/events`, label: `Festival Events`, title: `Boswell Book Festival 2024 events and information` },
			{ to: `/2024/childrens-festival-events`, label: `Children's Festival Events`, title: `Boswell Book Festival 2024 Children's Festival events and information` },
		],
		support: [
			{ to: `/friends`, label: `Become a Friend`, title: `Become a Friend of the Boswell Book Festival` },
			{ to: `/patrons`, label: `Become a Patron`, title: `Become a Patron of the Boswell Book Festival` },
			// { to: `/volunteers`, label: `Become a Volunteer`, title: `Become a Volunteer at the Boswell Book Festival` },
			{ to: `/donate`, label: `Donate`, title: `Donate funds to support the Boswell Book Festival` },
		],
		contact: [
			{ to: `/contact`, label: `Get in touch`, title: `Contact the Boswell Book Festival team` },
			{ to: `/join-our-mailing-list`, label: `Join our mailing list`, title: `Join the Boswell Book Festival mailing list` }
		]
	}

	const subNavLinks = (category) => {
		return (
			<>
				{subNavData[category]?.map((link, index) => {
					const key = `subNavLink_category_${index}`
					return <React.Fragment key={key}>{subNavLink(link)}</React.Fragment>
				})}
			</>
		)
	}

	const subNavLink = (link) => {
		const subNavLinkStyles = `block p-2 m-1 uppercase text-sm text-white text-center hover:bg-bbf-dark-green transition-all`
		const activeSubNavLinkStyles = `bg-bbf-pink text-white`

		return (
			<Link
				className={subNavLinkStyles}
				activeClassName={activeSubNavLinkStyles}
				to={link.to}
				title={link.title}
				onClick={handleToggleNav}
				state={link.locationState}
			>{link.label}</Link>
		)
	}

	const navLinks = () => {
		return (
			<>
				{navData.map((link, index) => {
					const key = `navLink_${index}`
					return <React.Fragment key={key}>{navLink(link)}</React.Fragment>
				})}
				{ shopEnabled && <SnipcartShoppingCart buttonClassName={`block mx-auto mt-4 md:mx-1 md:mt-0`}/> }
			</>
		)
	}

	const navLink = (link) => {
		const linkStyles = `block p-2 mx-1 uppercase font-bold text-sm text-white text-center mt-4 md:mt-0 md:text-left font-heading hover:bg-bbf-pink hover:shadow-md transition-all`
		const activeLinkStyles = `bg-bbf-pink text-white shadow-md`

		const navLink = (
			<Link
				className={linkStyles}
				activeClassName={activeLinkStyles}
				to={link.to}
				title={link.title}
			>{link.label}</Link>
		)

		const flyoutNavLink = (
			<Tippy
				content={subNavLinks(link.category)}
				animation="perspective"
				placement="bottom"
				interactive="true"
				theme="nav"
				arrow={false}
				inertia={true}
			>{navLink}</Tippy>
		)

		if (link.subNav) {
			return (
				<>
					{navIsOpen ? navLink : flyoutNavLink}
					<div className="block md:hidden">
						{subNavLinks(link.category)}
					</div>
				</>
			)
		} else {
			return navLink
		}
	}

	const overlayNav = () => {
		return (
			<nav className={`md:hidden fixed z-10 p-4 bg-bbf-dark-green top-0 left-0 right-0 bottom-0 flex-col items-center md:flex-row ml-auto overflow-y-auto`}>
				<NavHamburger handleToggleNav={handleToggleNav} navIsOpen={navIsOpen} />
				{navLinks()}
				<div className="mt-12 w-1/3 mx-auto">
					<DonateButton />
				</div>
			</nav>
		)
	}

	const nestedNav = () => {
		return (
			<nav className={`hidden md:flex flex-row items-center justify-end flex-wrap ml-auto`}>
				{navLinks()}
				<div className="md:ml-2">
					 <DonateButton />
					{/*<DonateFlyout />*/}
				</div>
			</nav>
		)
	}

	return (
		<div className="relative">
			<NavHamburger handleToggleNav={handleToggleNav} navIsOpen={navIsOpen} />
			{navIsOpen && overlayNav() }
			{nestedNav()}
		</div>
	)
}

export default Navigation
