import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PrismicSponsorSwiper from './PrismicSponsorSwiper'

const PrismicSponsorBanner = (props) => {
	const data = useStaticQuery(graphql`{
		sponsors: allPrismicSponsors (sort:{fields:data___sponsor_name___text}){
			nodes {
				data {
					sponsor_active
					sponsor_link {
						url
					}
					sponsor_logo {
						gatsbyImageData
					}
					sponsor_name {
						text
					}
				}
			}
		}
	}
`)

	const sponsors = data.sponsors.nodes.filter(sponsor => {
		return sponsor.data.sponsor_active === true
	})

	return (
		<section className={`py-4`}>
			<PrismicSponsorSwiper sponsors={sponsors} />
		</section>
	)
}

export default PrismicSponsorBanner
