import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import Header from './Header'
import Footer from './Footer'
import PrismicSponsorBanner from '../Sponsors/PrismicSponsorBanner'
import CookieConsent from 'react-cookie-consent'

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
					config {
						boxOfficeEnabled
						boxOfficePresale
						festivalYear
						shopEnabled
					}
				}
			}
		}`)

	return (
		<div className="antialiased relative font-sans bg-bbf-dark-green min-h-screen">
			<CookieConsent
				location={`bottom`}
				buttonText={`Accept`}
				enableDeclineButton
				declineButtonText={`Decline`}
				cookieName={`gatsby-gdpr-google-analytics`}
				disableStyles
				containerClasses={`fixed bg-bbf-dark-green shadow-lg right-0 bottom-0 left-0 z-50 p-4 sm:p-8 sm:flex sm:items-center text-xs md:text-sm text-white `}
				buttonWrapperClasses={`pt-4 sm:pt-0 sm:pl-8 flex flex-row-reverse items-center justify-around`}
				buttonClasses={`outline-none p-2 px-3 mx-2 shadow text-sm uppercase text-black bg-bbf-yellow transition-all hover:shadow-md`}
				declineButtonClasses={`outline-none p-2 px-3 mx-2 bg-green-900 shadow text-sm uppercase text-white`}
			>
				<p className={`mb-2`}>The Boswell Book Festival website stores cookies on your computer. These cookies are used to collect information about how you interact with this website and allow us to remember you.</p>
				<p className={`mb-2`}>We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors on this website. <Link className={`border-b-2 border-transparent hover:border-white transition-all`} to={`/privacy`}>View our privacy and cookie policy</Link>.</p>
				<p className={``}>If you decline, your information won’t be tracked when you visit this website. A single cookie will be used in your browser to remember your preference not to be tracked.</p>
			</CookieConsent>
			<Header siteMetadata={data.site.siteMetadata} showSupportBanner={false} />
			{/* need the padding bottom for the sticky(ish) footer */}
			<main className="pb-32 min-h-screen-60 bg-white">
				{children}
				<PrismicSponsorBanner />
			</main>
			<Footer />
		</div>
	)
}

export default Layout
