import React from 'react'

const NavHamburger = (props) => {

	return (
		<button
			type="button"
			className={`${props.navIsOpen ? 'block ml-auto' : 'fixed top-0 right-0 mt-2 mr-2 z-10'} md:hidden text-bbf-mid-green-alt focus:outline-none`}
			onClick={props.handleToggleNav}
		>
			<svg className="h-8 w-8 fill-current" viewBox="0 0 24 24">
				{props.navIsOpen ? (
					<path
						fillRule="evenodd"
						d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
					/>
				) : (
					<path
						fillRule="evenodd"
						d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
					/>
				)}
			</svg>
		</button>
	)
}

export default NavHamburger
