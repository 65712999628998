import React, { useState, createRef } from "react"
import PaperPlaneIcon from '../Icon/PaperPlaneIcon'
import { validateEmail } from '../../utils/validation'
import {
	FORM_LABEL_STYLES,
	FORM_INPUT_STYLES,
	FORM_INPUT_INVALID_STYLES,
	FORM_BUTTON_STYLES,
	FORM_BUTTON_INVALID_STYLES
} from '../../constants/form'

const MailchimpNewsletterForm = (props) => {
	const [email, setEmail] = useState('')
	const [emailIsValid, setEmailIsValid] = useState(true)

	const formRef = createRef()

	const handleEmailChange = (event) => {
		if (event.target.value !== email) {
			setEmail(event.target.value)
			setEmailIsValid(validateEmail(event.target.value))
		}
	}

	const handleSubmit = (event) => {
		if (emailIsValid && email.length) {
			setEmailIsValid(true)
			formRef.current.submit()
		} else {
			setEmailIsValid(false)
		}
	}

	const inputLabelStyles = props.footer === true ? `text-bbf-pink font-bold text-xl` : FORM_LABEL_STYLES
	const inputStyles =
		props.footer === true
			? `w-full p-2 text-lg bg-transparent border-2 border-bbf-${emailIsValid ? 'mid-green-alt' : 'red'} transition-all`
			: `${FORM_INPUT_STYLES} ${!emailIsValid && FORM_INPUT_INVALID_STYLES}`
	const buttonStyles =
		props.footer === true
			? `button mt-2 md:mt-0 md:ml-2 flex items-center bg-bbf-mid-green-alt text-white p-2 border-2 border-bbf-mid-green-alt hover:shadow-md transition-all ${emailIsValid ? '' : 'cursor-not-allowed'}`
			: `mt-2 md:mt-0 md:ml-2 flex items-center ${FORM_BUTTON_STYLES} ${!emailIsValid && FORM_BUTTON_INVALID_STYLES}`

	return (
		<div id="mc_embed_signup" className={` ${props.className}`}>
			<h2 className={`text-sm uppercase mb-2 text-center md:text-left`}>Join our mailing list</h2>
			<form
				action="https://boswellbookfestival.us6.list-manage.com/subscribe/post?u=877a9fa5e1&amp;id=16e5c44cbd"
				method="post"
				ref={formRef}
				onSubmit={handleSubmit}
				id="mc-embedded-subscribe-form"
				name="mc-embedded-subscribe-form"
				className="validate"
				target="_blank"
				noValidate>
				<div id="mc_embed_signup_scroll" className={`flex flex-col md:flex-row items-center`}>
					<div className={`mc-field-group w-full`}>
						<label htmlFor="mce-EMAIL" className={inputLabelStyles}>
							<input
								type="email"
								value={email}
								onChange={handleEmailChange}
								name="EMAIL"
								className={`required email ${inputStyles}`}
								id="mce-EMAIL"
								placeholder={`your@email.address`}
								autoFocus={props.autoFocus === true}
							/>
						</label>
					</div>
					<div id="mce-responses" className="clear">
						<div className="response" id="mce-error-response" style={{ display: 'none'}}></div>
						<div className="response" id="mce-success-response" style={{ display: 'none'}}></div>
					</div>
					<div className={`hidden`} aria-hidden="true">
						<input type="text" name="b_877a9fa5e1_16e5c44cbd" tabIndex="-1" />
					</div>
					<div className="clear ">
						<button
							type="button"
							value="Subscribe"
							name="subscribe"
							id="mc-embedded-subscribe"
							className={buttonStyles}
							disabled={!emailIsValid}
							onClick={handleSubmit}
						>
							<span className={`mr-2 text-lg uppercase`}>Join</span>
							<PaperPlaneIcon />
						</button>
					</div>
				</div>
				<p className={`${emailIsValid ? 'invisible' : ''} text-xs mt-1 text-bbf-red text-center md:text-left`}>Please enter a valid email address</p>
			</form>
		</div>
	)
}

export default MailchimpNewsletterForm
