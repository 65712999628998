import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import SwiperCore, { Autoplay, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'

SwiperCore.use([Autoplay, Pagination, Scrollbar, A11y])

const renderSlide = (sponsor, index) => {

	return (
		<SwiperSlide key={index} className={`p-4 md:px-12`}>
			<a href={sponsor.sponsor_link.url} title={sponsor.sponsor_name} target={`_blank`} rel={`noopener nofollow`}>
				<GatsbyImage
					image={sponsor.sponsor_logo.gatsbyImageData}
					alt={`Sponsor Logo ${sponsor.sponsor_name}`}
					objectFit="contain"
					className={`shadow-md`} />
			</a>
		</SwiperSlide>
	)
}

const PrismicSponsorSwiper = (props) => {
	// breakpoints match those defined in tailwind - https://tailwindcss.com/docs/responsive-design

	const { sponsors } = props

	return (
		<Swiper
			slidesPerView={2}
			spaceBetween={8}
			breakpoints={{
				640: {
					slidesPerView: 2,
					spaceBetween: 12
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 16
				},
				1024: {
					slidesPerView: 4,
					spaceBetween: 20
				},
				1280: {
					slidesPerView: 5,
					spaceBetween: 24
				},
				1536: {
					slidesPerView: 6,
					spaceBetween: 32
				},
				1920: {
					slidesPerView: 7,
					spaceBetween: 32
				}
			}}
			autoplay={{delay: 5000, disableOnInteraction: false}}
			className={``}
		>
			{sponsors.map((sponsor, index) => {
				return renderSlide(sponsor.data, index)
			})}
		</Swiper>
	)
}

export default PrismicSponsorSwiper
