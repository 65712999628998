import * as React from 'react'
import ShoppingCartIcon from "../Icon/ShoppingCartIcon"
import { isBrowser } from '../../utils/common'

function SnipcartShoppingCart(props) {
	const { buttonClassName } = props

	// TODO: window.Snipcart.store doesn't exist in incognito mode, so we need a way to let that work without error

	const SnipcartShoppingCartCount = (props) => {
		const { count } = props
		if (count) {
			return (
				<p className={`snipcart-items-count font-bold absolute top-0 right-0 left-0 -mt-2 -mr-1 min-w-4 min-h-4 text-xs`}>{count}</p>
			)
		}
		return null
	}

	return (
		<button className={`snipcart-checkout relative text-white ${buttonClassName}`}>
			<ShoppingCartIcon />
			<SnipcartShoppingCartCount count={isBrowser() ? window.Snipcart?.store.getState().cart.items.count : null} />
		</button>
	)
}

export default SnipcartShoppingCart
