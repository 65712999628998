/**
 *
 * @param email string
 */
export const validateEmail = email => {
	const regex = /\S+@\S+\.\S+/
	return regex.test(email)
}

/**
 *
 * @param value string
 */
export function validateEmpty(value) {
	return value.trim() !== ''

}

export function validateUkPostcode(value) {
	// regex from the simplified patterns in the long answer here:
	// https://stackoverflow.com/questions/164979/regex-for-matching-uk-postcodes
	const regex = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/
	return regex.test(value)
}

export function validateDonation(value) {
	return Number(value.trim()) > 0
}
