import React from 'react'
import { Link } from 'gatsby'
import { SocialIcon } from 'react-social-icons'
import MailchimpNewsletterForm from '../Form/MailchimpNewsletterForm'

const Footer = () => {

	return (
		<div id="footer" className="min-h-32 -mt-32 bg-gradient-to-bl from-bbf-dark-green to-green-900 text-white p-4 b-24 md:p-12 md:flex md:items-start">
			<div className="md:w-1/2">
				{/* newsletter signup form */}
				<MailchimpNewsletterForm className={`pb-8 max-w-md`} footer={true} />
				{/* trust information */}
				<p className="text-xs text-center md:text-left">The Boswell Book Festival is an event inaugurated and staged by The Boswell Trust, a charity registered in Scotland number SC041655 and a company limited by guarantee registered in Scotland SC380815.</p>
				<p className="text-xs pt-2 text-center md:text-left">Registered office: Auchendrane, By Ayr, KA7 4TW. The Trust is established for the advancement of heritage, literary arts and education, specifically the restoration of James Boswell's Mausoleum in Auchinleck Churchyard and the creation of a visitor attraction in the adjoining Boswell Aisle. </p>

				<p className="text-xs pt-2 text-center md:text-left">
					<Link to={`/privacy`} className={`border-b-2 border-transparent hover:border-white transition-all`}>Privacy and cookie policy</Link> | <Link to={`/terms`} className={`border-b-2 border-transparent hover:border-white transition-all`}>Website terms of use</Link>
				</p>
			</div>
			<div className="pt-8 md:pt-0 md:w-1/2 md:flex-col md:items-center">
				{/* social media links */}
				<div className="mx-auto md:w-48 flex items-center justify-around md:justify-between text-white pt-8 pb-10">
					<SocialIcon url="https://www.facebook.com/boswellbookfestival" />
					<SocialIcon url="https://www.instagram.com/boswellbookfestival" />
					<SocialIcon url="https://twitter.com/bozzyfest" />
				</div>
				{/* site links */}
				<p className={`text-xs text-center my-1`}>Made in Scotland by <a href={`https://www.codearcs.io`} className={`font-bold border-b-2 border-transparent hover:border-white transition-all`}>CodeArcs</a></p>
			</div>
		</div>
	)
}

export default Footer
