import * as React from "react";

function PaperPlaneIcon(props) {
	return (
		<svg
			viewBox="0 0 512 512"
			fill="currentColor"
			height="1em"
			width="1em"
			{...props}
		>
			<path d="M496 16L15.88 208 195 289 448 64 223 317l81 179L496 16z" />
		</svg>
	);
}

export default PaperPlaneIcon;
